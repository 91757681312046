import { useQuery } from '@tanstack/react-query'
import HeaderSearchResultsCategories from './HeaderSearchResultsCategories'
import HeaderSearchResultsPages from './HeaderSearchResultsPages'
import HeaderSearchResultsProducts from './HeaderSearchResultsProducts'
import Loader from '../../Loader/Loader'
import useCurrentLocale from '../../../../core/hook/useCurrentLocale'
import { useGetClientAlgoliaSearch } from '../../../../../queries/algolia/algolia.client'
import { useContext } from 'react'
import { ConfigContext } from '../../../context/ConfigContext'
import {
  CategoryHitAlgolia,
  PageHitAlgolia,
  ProductHitAlgolia,
} from '../../../../../queries/algolia/ResultFetchAlgoliaByQuery.types'
import { SearchResponse } from 'algoliasearch'

const useMultipleSearch = (valueSearch: string) => {
  const currentLocale = useCurrentLocale()
  const client = useGetClientAlgoliaSearch()
  const {
    storeConfig: { algoliasearch_credentials_credentials_index_prefix: indexPrefix },
  } = useContext(ConfigContext)

  const queryResult = useQuery({
    queryKey: ['search-multiple', valueSearch],
    queryFn: async () => {
      const params = {
        query: valueSearch,
        hitsPerPage: 3,
        analyticsTags: 'autocomplete',
        clickAnalytics: 'true',
      }
      const requests = [
        {
          indexName: `${indexPrefix}${currentLocale}_categories`,
          ...params,
        },
        {
          indexName: `${indexPrefix}${currentLocale}_products`,
          ...params,
          hitsPerPage: 6,
        },
        {
          indexName: `${indexPrefix}${currentLocale}_pages`,
          ...params,
        },
      ]
      const { results } = await client.search({ requests })
      const [categories, products, pages] = results as [
        SearchResponse<CategoryHitAlgolia>,
        SearchResponse<ProductHitAlgolia>,
        SearchResponse<PageHitAlgolia>,
      ]
      return { categories, products, pages }
    },
    enabled: !!valueSearch,
  })

  return queryResult
}

const HeaderSearchResults = ({
  valueSearch,
  onChange,
}: {
  valueSearch: string
  onChange: (valueSearch: string) => void
}) => {
  const { data: result, isLoading, isError } = useMultipleSearch(valueSearch)

  if (!valueSearch) {
    return null
  }
  if (isLoading) {
    return null
  }
  if (isError) {
    return null
  }

  const { categories, products, pages } = result

  return (
    <>
      {valueSearch && result ? (
        <div className='header-search-results'>
          <div className='header-search-results-left'>
            <HeaderSearchResultsProducts onChange={onChange} valueSearch={valueSearch} products={products.hits} />
          </div>
          <div className='header-search-results-right'>
            <HeaderSearchResultsCategories categories={categories.hits} />
            <HeaderSearchResultsPages pages={pages.hits} />
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default HeaderSearchResults
