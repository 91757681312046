'use client'

import { ProductHitAlgolia } from '../../../../../queries/algolia/ResultFetchAlgoliaByQuery.types'
import { useTranslation } from '../../../context/ConfigContext'
import { Button } from '../../Button/Button'
import { CartItemForHeaderSearch } from '../../CartItem/CartItem'
import Link from '../../Link/Link'
import { buildUrlSearchProductsPage } from './HeaderSearch.utils'
import { gtmPush, gtmCategory } from '../../../utils/gtmHelpers'
import useCurrentLocale from '../../../../core/hook/useCurrentLocale'
import { useQuery } from '@tanstack/react-query'
import { TopSearchesResponse } from 'algoliasearch'

type HeaderSearchResultsProductsProps = {
  valueSearch: string
  products: ProductHitAlgolia[]
  onChange(valueSearch: string): void
}

const useTopSearchesQuery = (options: { enabled?: boolean }) => {
  const currentLocale = useCurrentLocale()
  return useQuery({
    ...options,
    queryKey: ['topSearches'],
    async queryFn() {
      const response = await fetch(`/api/algolia/topSearches?store=${currentLocale}`)
      if (response.ok) {
        // Support old data schema
        return response.json() as Promise<
          { topSearches: TopSearchesResponse } | TopSearchesResponse
        >
      }
    },
  })
}

const HeaderSearchResultsProducts = ({
  valueSearch,
  products,
  onChange,
}: HeaderSearchResultsProductsProps) => {
  const t = useTranslation()
  const topSearchesQuery = useTopSearchesQuery({
    enabled: products.length === 0,
  })
  const topSearchesQueryData = topSearchesQuery.data || { topSearches: { searches: [] } }
  const topSearches =
    'topSearches' in topSearchesQueryData ? topSearchesQueryData.topSearches.searches : []

  const gtmProductPush = (product: ProductHitAlgolia, index: number) => {
    const gtmCategoryObj = gtmCategory(product.categories.level0.join('/'))
    const gtmPosition = (index += 1)

    gtmPush({
      event: 'select_item',
      ecommerce: {
        item_list_id: 'recherche',
        item_list_name: 'Recherche',
        items: {
          item_name: product.gtm_name ?? product.name,
          item_id: product.sku[0],
          price: product.price?.EUR.default,
          item_brand: 'Messika',
          index: gtmPosition ?? '',
          item_list_id: 'recherche',
          item_list_name: 'Recherche',
          ...gtmCategoryObj,
        },
      },
    })

    const productValues = {
      position: gtmPosition,
      listId: 'recherche',
      listName: 'Recherche',
    }

    localStorage.setItem('productValues', JSON.stringify(productValues))
  }

  if (!products.length) {
    return (
      <div className='header-search-results-products noresult'>
        <p className='noresult-title'>
          {t('No products for query')}: {valueSearch}
        </p>
        {topSearches.length > 0 && (
          <div>
            <p>{t('Most frequent searches')}</p>
            <ul className='mfs'>
              {topSearches.map((item) => {
                return (
                  <li key={item.search} className='mfs-item'>
                    <button type='button' onClick={() => onChange(item.search)}>
                      {item.search}
                    </button>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
        <p className='noresult-link'>
          <Link href={buildUrlSearchProductsPage()}>{t('See all products', {}, true)}</Link>
        </p>
      </div>
    )
  }

  return (
    <div className='header-search-results-products'>
      <p className='header-search-results-title'>{t('Products', {}, true)}</p>
      <ul className='header-search-results-products-list'>
        {products.map((product, index) => (
          <li className='header-search-results-products-item' key={product.objectID}>
            <Link href={product.url} onClick={() => gtmProductPush(product, index)}>
              <CartItemForHeaderSearch product={product} />
            </Link>
          </li>
        ))}
      </ul>
      <div className='header-search-results-products-button'>
        <Button
          classname='button black'
          label={t('See all products', {}, false)}
          href={buildUrlSearchProductsPage(valueSearch)}
          style='dark'
        />
      </div>
    </div>
  )
}

export default HeaderSearchResultsProducts
